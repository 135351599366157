<template>
  <div class="container">
    <div class="columns">
      <div class="column login-box card is-offset-3 is-6 content">
        <div class="p-md">
          <div class="has-text-centered">
            <h2 class="">
              Update Profile Information
            </h2>
          </div>
          <registration-form
            cur-screen="profile"
            :user-type="curUserData.role"
            :form-data="curUserData"
            @form-submit="submitForm"
            @update-password-submit="changePassword"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationForm from '@/components/RegistrationForm';
export default {
  components: {
    RegistrationForm,
  },
  data() {
    return {
      curUserData: {},
    };
  },
  async fetch({ store }) {
    await store.dispatch('users/getCurUserData');
    return Promise.resolve();
  },
  beforeMount() {
    if (Object.keys(this.$store.state.users.curUserData).length > 0) {
      this.curUserData = JSON.parse(JSON.stringify(this.$store.state.users.curUserData));
    }
  },
  methods: {
    async changePassword(payload) {
      await this.$store.dispatch('users/changePassword', payload);
      // if (response) {
      //   this.$success('Your password has been updated.');
      // }
    },
    async submitForm(formData) {
      formData.page = 'profile';
      const response = await this.$store.dispatch('users/update', formData);
      if (response) {
        this.$success('Your profile has been saved');
      }
    },
  },
};
</script>